import React, { useLayoutEffect, useState } from "react";
import Footer from "../Components/Footer";
import "../Css/About.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import MeetTheTeam from "../Components/MeetTheTeam";
import aboutUs from "../img/aboutUspng.jpg";
import p1 from "../img/partner/p1.png";
import p2 from "../img/partner/platinum corp.png";
import p3 from "../img/partner/p3.png";
import p4 from "../img/partner/p4.png";
import p5 from "../img/partner/p5.png";
import p6 from "../img/partner/p6.png";
import p7 from "../img/partner/p7.png";
import p8 from "../img/partner/p8.png";

import Arkade from "../img/partner/Arkade.png";
import birla_estates from "../img/partner/birla_estates.png";
import ekta_world from "../img/partner/ekta_world.png";
import elements_realty from "../img/partner/elements_realty.png";
import godrej from "../img/partner/godrej.png";
import hiranandani from "../img/partner/hiranandani.png";
import K_raheja_corp from "../img/partner/K_raheja_corp.png";
import kolte_patil from "../img/partner/kolte_patil.png";
import LandT_realty from "../img/partner/LandT_realty.png";
import lodha from "../img/partner/lodha.png";
import Nagpal_Developers from "../img/partner/Nagpal_Developers.png";
import Paradigm from "../img/partner/Paradigm.png";
import parinee from "../img/partner/parinee.png";
import prestige_group from "../img/partner/prestige_group.png";
import raymond_realty from "../img/partner/raymond_realty.png";
import shapoorji_pallonji from "../img/partner/shapoorji_pallonji.png";
import supreme from "../img/partner/supreme.png";
import The_house_of_abhinandan_Lodha from "../img/partner/The_house_of_abhinandan_Lodha.png";
import The_wadhwa_group from "../img/partner/The_wadhwa_group.png";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Button, Form, Input, message, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { post } from "../services/ApiRouting";
import PopUp from "./PopUp";

// ========== About read more ====
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(70, 200) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

const About = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    // setIsModalOpen(true); // Open modal on page load
  }, []);

  return (
    <>
      {/* =========== Section 1 ========== */}
      <div className="About-Section1">
        <div className="aboutBanner">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="about_BannerDesc">
                  <h1>About Us</h1>
                  {/* <p>
                    Professional real estate brokerage specialising in purchase,
                    sale and leasing of residential and commercial properties in
                    Mumbai.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== About Section2 ================ */}
      <section className="aboutSection2">
        <div className="container">
          <div className="row">
            <div className="col-md-5 d-block d-md-none">
              <div className="aboutImg-con">
                <div className="aboutImg">
                  <img
                    src={aboutUs}
                    // https://sdgrealty.in/wp-content/uploads/2023/01/slider2-1536x1153.jpg
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <div className="aboutList">
                <div className="aboutDesc">
                  <h2>
                    <span>About SDG </span> Realty
                  </h2>
                  <p>
                    At SDG Realty, we are committed to providing top-notch real
                    estate services through our skilled professionals, detailed
                    property analysis, and efficient property search processes.
                    Our dedication to client satisfaction and our extensive
                    experience make us the preferred choice for discerning
                    clients in Mumbai. Our goal is to simplify the property
                    search process, saving you time and effort while ensuring
                    you find the property that perfectly fits your needs. With a
                    reputation built on trust and a track record of successful
                    projects, we are committed to upholding the highest
                    standards of professionalism, transparency, and ethical
                    conduct.
                  </p>
                  <p>
                    SDG Realty is a distinguished division of the Shailesh Doshi
                    Group, founded in 1987 by Mr. Shailesh Doshi. Their sons,
                    Mr. Jay Doshi and Mr. Saahil Doshi are actively involved in
                    realty business. With over 36 years of expertise in the real
                    estate sector, SDG Realty is centrally located in the
                    vibrant neighbourhood of Juhu, Mumbai. We specialize in the
                    acquisition, sale, and leasing of residential and commercial
                    properties throughout Mumbai, India.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-5 d-none d-md-block">
              <div className="aboutImg-con">
                <div className="aboutImg">
                  <img
                    src={aboutUs}
                    // https://sdgrealty.in/wp-content/uploads/2023/01/slider2-1536x1153.jpg
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <p>
                <p>
                  At SDG Realty, we pride ourselves on a seasoned team of
                  professionals committed to the highest standards of
                  professionalism, transparency, and ethical conduct. With
                  decades of experience, we go above and beyond to meet your
                  expectations and provide you with the precise knowledge you
                  need.
                </p>
                <p>
                  Over the years, SDG Realty has established itself as a leading
                  authority in property consulting, thanks to our dedicated team
                  that deeply understands and prioritizes client needs. Whether
                  you envision a spacious house, a dream apartment, or any other
                  type of property, we are here to turn your vision into
                  reality.
                </p>
                <p>
                  Our stellar reputation is built on trust and a track record of
                  successful projects. Explore our wide range of services and
                  discover why discerning clients consistently choose SDG Realty
                  for all their real estate needs.
                </p>
                <p>
                  At SDG Realty, we leverage our experience and expertise across
                  the entire sales process, including strategic marketing,
                  financing options, skillful negotiations, and more. Our
                  extensive network comprises reputable and trusted real estate
                  professionals, ensuring you have access to comprehensive
                  options without compromise.
                </p>
                <p>
                  We take an impartial approach, offering realistic assessments
                  of your property and available choices. Your satisfaction is
                  our priority, which is why we prioritize open communication
                  and understanding your preferences before proceeding with any
                  transaction.
                </p>
                <p>
                  Our robust experience in due diligence and title verification
                  instils confidence in our clients, ensuring peace of mind
                  throughout the property transaction process.
                </p>
                <p>
                  Choose SDG Realty for a professional, trustworthy, and
                  personalized real estate experience. Let us help you achieve
                  your property goals with integrity and expertise.
                </p>
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* ================== About Section3 ================ */}
      {/* <section className="aboutSection3">
        <div className="container">
          <div className="headTitle">
            <h2><span> Who We </span> Are</h2>
            <p>
              SDG Reality is a top-tier real estate brokerage firm, recognized as a prominent property broker throughout Mumbai. With a rich pedigree of experience in the field of real estate brokerage, we excel at facilitating seamless transactions for our clients across Mumbai. Our mission is to assist customers in navigating any real estate transaction with ease and expertise.
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="missionCard">
                <div className="aboutMissionImg">
                  <img src={Mission} alt="" />
                </div>
                <h4>Our Mission</h4>
                <p>
                  To be known in the Indian Real Estate community because of our
                  Experience, excellence, integrity & honesty
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="missionCard">
                <div className="aboutMissionImg">
                  <img src={Vision} alt="" />
                </div>
                <h4>Our Vision</h4>
                <p>
                  Our vision is to be one of the most respected real estate
                  companies in India through a very high fidelity fulfillment of
                  promises made to customers.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="missionCard">
                <div className="aboutMissionImg">
                  <img src={Goal} alt="" />
                </div>
                <h4>Trust & Transparency</h4>
                <p>
                  To provide the most professional, informative, dedicated and
                  prompt service in the real estate industry with Honesty and
                  Transpancy
                </p>
              </div>
            </div>
          </div>
          
        </div>
      </section> */}
      {/* ================== About Section 4 ================ */}
      {/* <Founder /> */}
      <MeetTheTeam />
      {/* =================  AboutSection 5 ================ */}
      {/* <section className="AboutCounter">
        <div className="container">
          <div className="headTitle">
            <h2>Our Client Say</h2>
          </div>
          <div className="row row-cols-lg-4 row-cols-2">
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span><AiOutlineCalendar /></span>
                      <span className="countingClass">
                        <span className="count">
                          <CountUp end={36} duration={5} />
                        </span>
                        <span> +</span>
                      </span>
                      <span>Year of Experience</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span><SlPeople /></span>
                      <span className="countingClass">
                        <span className="count">
                          <CountUp end={1200} duration={5} />
                        </span>
                        <span> +</span>
                      </span>
                      <span>No of Client</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span><RiEmotionHappyLine /></span>
                      <span className="countingClass">
                        <span className="count">
                          <CountUp end={1000} duration={5} />
                        </span>
                        <span> +</span>
                      </span>
                      <span>Happy Users</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="countPanel">
                <div className="panelCounter">
                  <ul id="counter">
                    <li>
                      <span><MdOutlineRateReview /></span>
                      <span className="countingClass">
                        <span className="count">
                          <CountUp end={700} duration={5} />
                        </span>
                        <span> +</span>
                      </span>
                      <span>Google Review</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* ================== about Section5 ================ */}
      <section className="aboutbrands">
        <div className="container">
          <div className="headTitle">
            <h2>
              <span>Our Associate</span> Partner
            </h2>
            {/* <p>
            Unlocking your dream home's potential.With a top best property dealers
            </p> */}
          </div>
          <div className="row">
            <Swiper
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
              modules={[Autoplay]}
              className="Brands"
            >
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p1} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p2} alt="" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p3} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p4} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p5} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p6} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p7} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={p8} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={Arkade} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={birla_estates} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={ekta_world} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={elements_realty} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={godrej} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={hiranandani} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={K_raheja_corp} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={kolte_patil} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={LandT_realty} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={lodha} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={Nagpal_Developers} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={Paradigm} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={parinee} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={prestige_group} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={raymond_realty} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={shapoorji_pallonji} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={supreme} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={The_house_of_abhinandan_Lodha} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div class="brand_img">
                  <img src={The_wadhwa_group} alt="Jonas Blue" />
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <Footer />

   <PopUp/>
    </>
  );
};

export default About;
