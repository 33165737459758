import "./App.css";
import NavBar from "./Components/NavBar/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Properties from "./Pages/Properties";
import PropertyView from "./Pages/PropertyView";
import PropertyData from "./Components/PropertyData";
import RightSideIcon from "./Components/RightSideIcon";
import ResidentialProperty from "./Components/ResidentialProperty";
import CommercialProperty from "./Components/CommercialProperty";
import Award from "./Pages/Award";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBar />
        <RightSideIcon />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/residential-property" element={<ResidentialProperty />} />
          <Route path="/commercial-property" element={<CommercialProperty />} />
          <Route path="/award" element={<Award />} />
          {PropertyData.map((route, index) => (
            <Route
              key={index}
              exact
              path={`/${route.type}/${route.propertyType}`}
              element={<Properties />}
            />
          ))}
          {/* {PropertyData.map((route, index) => (
            <Route
              key={index}
              exact
              path={`/${route.type}/${route.propertyType}/${route.slugs}`}
              element={<PropertyView />}
            />
          ))} */}
          <Route path="/:slugs/:area/:category" element={<PropertyView />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
