import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../Css/InstagramPosts.css";
import { FaFacebookF } from "react-icons/fa";
export default function InstagramPosts() {
  return (
    <>
      {" "}
      <section className="homeInsta">
        <div className="container">
          <div className="headTitle">
            <h2>
              Instagram <span>Feeds</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-12 mb-4 d-flex align-items-center justify-content-center">
              <div
                class="elfsight-app-f2369640-2153-4e0f-93e3-073805ec6f3a"
                data-elfsight-app-lazy
              ></div>
            </div>
            {/* <div className="col-md-6">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsdgrealty&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width={340}
              height={500}
              style={{ border: "none", overflow: "hidden" }}
              scrolling="no"
              frameBorder={0}
              allowFullScreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            />
          </div> */}
          </div>
        </div>
      </section>
      <section className="homeInsta" style={{ background: "aliceblue" }}>
        <div className="container">
          <div className="headTitle">
            <h2>
              Facebook <span>Feeds</span>
            </h2>
          </div>
          <div className="home-facebook-btn-con">
            <a href="https://www.facebook.com/sdgrealty" target="blank">
              <div className="home-facebook-btn">
                <h3>SDG Realty</h3>
                <span>
                  <FaFacebookF />
                </span>
              </div>
            </a>

          </div>
          {/* <div className="row">
            <div className="col-md-6 d-flex align-items-center justify-content-center d-none d-md-flex">
              <img
                style={{ maxWidth: "500px", width: "100%", margin:"auto" }}
                src="https://cdni.iconscout.com/illustration/free/thumb/free-facebook-advertisement-tutorial-4277121-3561283.png?f=webp"
              />
            </div> */}
          {/* <div className="d-flex align-items-center justify-content-center">
              <iframe
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsdgrealty&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                width={340}
                height={500}
                style={{ border: "none", overflow: "hidden" }}
                scrolling="no"
                frameBorder={0}
                allowFullScreen="true"
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
              />
            </div> */}
          {/* </div> */}
        </div>
      </section>
    </>
  );
}
