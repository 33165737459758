import React from "react";
import { GiRotaryPhone } from "react-icons/gi";
import { SiGooglemaps } from "react-icons/si";
import { AiFillClockCircle } from "react-icons/ai";
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import Logo from "../img/Footer Logo.png";
import { FaAnglesRight, FaRegRegistered } from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="footerDesc">
                <Link to="/">
                  <div className="footer-logo">
                    <img src={Logo} alt="" />
                  </div>
                </Link>
                {/* <p>
                Shailesh Doshi Group is one stop property solution company, who helps you in Buying, Selling and leasing of properties all across Mumbai. Shailesh Doshi Group are panel brokers for many companies in India and overseas.
                </p> */}
              </div>
              <div className="footerImg"></div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-4">
                  <div class="footerUseful_link">
                    <h4>Quick Link</h4>
                    <ul>
                      <li>
                        <FaAnglesRight />
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/award">Award</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="Footer-MainSection-col-2">
                    <h4>Contact Info</h4>
                    <div className="Footer-iconsDetails-con">
                      <p>
                        <i>
                          <FaRegRegistered />
                        </i>
                        <span>RERA No – A51800040408</span>
                      </p>
                      {/* <div className="Footer-iconsDetails">
                    <div className="Footer-icons">
                      <SiGooglemaps />
                    </div>
                    <div className="Footer-Details">
                      <p>
                        SDG Realty, 37/1468, Ground Floor, Sahyadri Chs, Next to
                        “The Club”, D.N. Nagar, Andheri (W), Mumbai - 400 053.
                      </p>
                    </div>
                  </div> */}
                      {/* <div className="Footer-iconsDetails">
                    <div className="Footer-icons">
                      <AiFillClockCircle />
                    </div>
                    <div className="Footer-Details">
                      <p>Mon - Sat 8.00 - 18.00 Sunday CLOSED</p>
                    </div>
                  </div> */}
                      <div className="Footer-iconsDetails">
                        <div className="Footer-icons">
                          <GiRotaryPhone />
                        </div>
                        <div className="Footer-Details">
                          <span>
                            <a href="tel:91-9029291424">+91-9029291424 ( Saahil Doshi )</a>
                          </span>
                        </div>
                      </div>
                      <div className="Footer-iconsDetails">
                        <div className="Footer-icons">
                          <GiRotaryPhone />
                        </div>
                        <div className="Footer-Details">
                          <span>
                            <a href="tel:+91-9870847778">+91-9870847778 ( Jay Doshi )</a>
                          </span>
                        </div>
                      </div>
                      <div className="Footer-iconsDetails">
                        <div className="Footer-icons">
                          <GiRotaryPhone />
                        </div>
                        <div className="Footer-Details">
                          <span>
                            <a href="tel:9820035925">+91-9820035925 ( Shailesh Doshi )</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4">
                  <div class="footerProperty_Types">
                    <h4>Property Types</h4>
                    <ul>
                      <li>
                        <FaAnglesRight />
                        <Link to="/residental-property">Flat/Apartment</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/commercial-property">
                          Bungalow/Row House
                        </Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/residental-property">Duplex/Penthouse</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/commercial-property">Office/Shop</Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="col-lg-5">
                  <div class="footerProperties_Available">
                    <h4>Properties Available </h4>
                    <ul>
                      <li>
                        <FaAnglesRight />
                        <Link to="/About">Juhu/JVPD Scheme (18)</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/residental-property">Andheri (10)</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/commercial-property">Vile Parle (12)</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/Services">Bandra (8)</Link>
                      </li>
                      <li>
                        <FaAnglesRight />
                        <Link to="/Contact">Khar (5)</Link>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="Footer-MainSection-col-2">
                <h4>Location</h4>
                <div className="Footer-iconsDetails">
                  <div className="Footer-icons">
                    <SiGooglemaps />
                  </div>
                  <div className="Footer-Details">
                    <a href="https://maps.app.goo.gl/6YQLBvZm2YdDGPmS7" target="blank">
                      SDG Realty, 37/1468, Ground Floor, Sahyadri Chs, Next to
                      “The Club”, D.N. Nagar, Andheri (W), Mumbai - 400 053.
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Footer_copyRight">
        <div className="container">
          <div className="row">
            <div class="col-md-6">
              <div class="copyright">
                <p>Copyright © 2024 SDG Realty All Rights Reserved </p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="develope-by">
                <p>
                  Designed by
                  <a href="https://skdm.in/">
                    Shree Krishna Digital Marketing
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
