const LeaseData = [
    {
        price: "25000",
        mainPrice: "25 K",
    },
    {
        price: "30000",
        mainPrice: "30 K",
    },
    {
        price: "50000",
        mainPrice: "50 K",
    },
    {
        price: "75000",
        mainPrice: "75 K",
    },
    {
        price: "100000",
        mainPrice: "1 Lacs",
    },
    {
        price: "150000",
        mainPrice: "1.5 Lacs",
    },
    {
        price: "300000",
        mainPrice: "3 Lacs",
    },
    {
        price: "500000",
        mainPrice: "5 Lacs",
    },
    {
        price: "1000000",
        mainPrice: "10 Lacs",
    },
    {
        price: "1500000",
        mainPrice: "15 Lacs",
    },
    {
        price: "2000000",
        mainPrice: "20 Lacs",
    },
    {
        price: "2500000",
        mainPrice: "25 Lack",
    },
]

export default LeaseData;